import axios from "@/api/baseRequest.js";

//根据条件列出文章
export const apiListArticleByCondition = (token, formData) =>
    axios.post("/api/v1/admin/article/list_by_condition", formData, {
        headers: {
            "token": token
        }
    });

//根据ID删除文章
export const apiDeleteArticle = (token, formData) =>
    axios.post("/api/v1/admin/article/delete_by_id", formData, {
        headers: {
            "token": token
        }
    });

export const apiAddArticle = (token, formData) =>
    axios.post("/api/v1/admin/article/add", formData, {
        headers: {
            "token": token
        }
    });

export const apiUpdateArticle = (token, formData) =>
    axios.post("/api/v1/admin/article/update", formData, {
        headers: {
            "token": token
        }
    });